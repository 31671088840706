<div class="tracks-container">
  <div
    class="track-container track-container--dados"
    [ngClass]="{'active': etapaAtiva >= 1}"
  >
    <div class="track"></div>
    <div class="circle"><span>Dados do Associado</span></div>
  </div>

  <!-- A etapa 2 não é mais necessária para o cliente. Talvez volte a ser um dia...
    <div
      class="track-container track-container--documentacao"
      [ngClass]="{'active': etapaAtiva >= 2}"
    >
      <div class="track"></div>
      <div class="circle"><span>Documentação</span></div>
    </div>
  -->

  <div
    class="track-container track-container--plano"
    [ngClass]="{'active': etapaAtiva >= 3}"
  >
    <div class="track"></div>
    <div class="circle"><span>Plano</span></div>
  </div>

  <div
    class="track-container track-container--concluido"
    [ngClass]="{'active': etapaAtiva >= 4}"
  >
    <div class="track"></div>
    <div class="circle"><span>Pedido<br>concluído</span></div>
  </div>
</div>
